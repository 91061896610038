/* You can add global styles to this file, and also import other style files */

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Segoe UI", "Open Sans", arial !important ;
  background-color: #fafafa;
}


