@use "@angular/material" as mat;
@include mat.core();

$user-management-interview-primary: mat.define-palette(mat.$deep-purple-palette);
$user-management-interview-accent: mat.define-palette(
  mat.$blue-grey-palette,
  100
);
$user-management-interview-warn: mat.define-palette(mat.$red-palette);
$user-management-interview-theme: mat.define-light-theme(
  (
    color: (
      primary: $user-management-interview-primary,
      accent: $user-management-interview-accent,
      warn: $user-management-interview-warn,
    ),
  )
);
@include mat.all-component-themes($user-management-interview-theme);
.overlay-backdrop {
  background-color: rgba($color: #fff, $alpha: 0.6);
}
